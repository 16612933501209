import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logo-bje.svg"
import ogimage from "../../../images/projekte/bje-playbook/thumbleiste/bje-playbook-01.jpg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/bje-playbook/bje-playbook.mp4'
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"


const BuschJaegerPlaybook = function (props, ref) {

    const data = useStaticQuery(graphql`
        query BuschJaegerPlaybookQuery {
            heropic: file(relativePath: { eq: "images/projekte/bje-playbook/landing/playbook-og.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7,
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/bje-playbook/landing/playbook-og.jpg" }) {
                childImageSharp {
                    fixed(height: 100) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/bje-playbook/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
            }
        }
    `)
    
    const specsData = [
        { title: "Eckdaten", objects: [logo, "Web App"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js", "react"],
        },
      ]

    return (
        <Layout>
            <SEO
                title="Busch-Jaeger Playbook"
                description="Q:marketing realisiert Vertriebsapp für den Außendienst von Busch-Jaeger."
                image={data?.ogimage?.childImageSharp?.fixed}
            />

            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage="">                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>Busch-Jaeger Playbook</h1>                                         
                        <p className="introtext">Die Playbook App ermöglicht es dem Busch-Jaeger Außendienst, in Verkaufsgesprächen mit Elektroinstallateuren und dem Elektrogroßhandel die Produktlösungen professionell zu präsentieren.</p>
                        <AnchorLink to="/projekte/sales-apps/busch-jaeger-playbook/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>                                                              
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>    

            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Die Vertriebs-App sorgt für eine einheitliche Beratungsqualität im Vertrieb, intensiviert Kundenbeziehungen, automatisiert Prozesse und liefert Kennzahlen zur Optimierung der Vertriebsstrategie.",
                        "Professionelle Produktpräsentation für den Außendienst.",                        
                        "Wünsche und Informationen des Kunden können strukturiert erfasst werden.",
                        "Digitale Zusammenfassung des Gesprächs mit Dialogfunktionen für die Kunden.",
                        "Synchronisation der Gesprächsdaten mit CRM-Systemen.",
                        "Klare Benutzerführung und einfache Usability, damit im Gespräch nichts vom Wesentlichen ablenkt."                        
                    ]}
                />
            </ListenModule>
            
            <Specs data={specsData} />
            
            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

        </Layout>
    )
}

export default BuschJaegerPlaybook